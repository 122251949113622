@font-face {
  font-family: "kosciol-webfont";
  src: url("./fonts/webfont/kosciol-webfont.eot");
  src: url("./fonts/webfont/kosciol-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/webfont/kosciol-webfont.woff") format("woff"), url("./fonts/webfont/kosciol-webfont.ttf") format("truetype"), url("./fonts/webfont/kosciol-webfont.svg#kosciol-webfont") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "untitled-font-1";
  src: url("./fonts/webfont/untitled-font-1.eot");
  src: url("./fonts/webfont/untitled-font-1.eot?#iefix") format("embedded-opentype"), url("./fonts/webfont/untitled-font-1.woff") format("woff"), url("./fonts/webfont/untitled-font-1.ttf") format("truetype"), url("./fonts/webfont/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "kosciol-webfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "kosciol-webfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-bigger-size:before {
  content: "\e000"; }

.icon-close-map-homepage:before {
  content: "\e001"; }

.icon-close-model:before {
  content: "\e002"; }

.icon-dropdown:before {
  content: "\e003"; }

.icon-readmore:before {
  content: "\e003"; }

.icon-readless {
  transform: rotate(180deg); }
  .icon-readless:before {
    content: "\e003"; }

.icon-facebook:before {
  content: "\e004"; }

.icon-kamera-ikona:before {
  content: "\e005"; }

.icon-komp-ikona:before {
  content: "\e006"; }

.icon-mikrofon:before {
  content: "\e007"; }

.icon-sygnet-home:before {
  font-family: "untitled-font-1" !important;
  content: "b"; }

.icon-strzalka-slider:before {
  content: "\e009"; }

.icon-strzaka-stopka:before {
  content: "\e00a"; }

.icon-slider-braz-strzalka:before {
  content: "\e00b"; }

.icon-pinterest:before {
  content: "\e00c"; }

.icon-pink-kontkat:before {
  content: "\e00d"; }

.icon-pin-mapa-outline:before {
  content: "\e00e"; }

.icon-oko-model-ikona:before {
  content: "\e00f"; }

.icon-oko-ikona:before {
  content: "\e010"; }

.icon-sygnet-home-kopia:before {
  font-family: "untitled-font-1" !important;
  content: "b"; }

.icon-tweeter:before {
  content: "\e012"; }

.icon-znaczek-stopka:before {
  content: "\e013"; }

.icon-poznaj-rece-ikona:before {
  font-family: "untitled-font-1" !important;
  content: "e"; }

.icon-strzaka-play:before {
  content: "\e015"; }

.icon-strzalka-kwadraty:before {
  content: "\e016"; }

.icon-strzalka-link:before {
  content: "\e017"; }

.icon-noc-ikona:before {
  content: "\e018"; }

.icon-dzien:before {
  content: "\e019"; }

.icon-oko-panorama:before {
  content: "\e01a"; }

.icon-oko:before {
  font-family: "untitled-font-1" !important;
  content: "c"; }

.icon-3dikona:before {
  font-family: "untitled-font-1" !important;
  content: "a"; }

.icon-plusik-07:before {
  content: "\e01d"; }

.icon-zegar-10:before {
  content: "\e01e"; }

.icon-rczki-ikona:before {
  content: "\e01f"; }

.icon-punktor-14:before {
  content: "\e020"; }

.icon-kamera-11:before {
  content: "\e021"; }

.icon-komputer-12:before {
  content: "\e022"; }

.icon-logologo:before {
  font-family: "untitled-font-1" !important;
  content: "b"; }

.icon-mapa:before {
  content: "\e024"; }

.icon-play-16:before {
  content: "\e025"; }

.icon-ikony-dziedzictwo-01:before {
  content: "\e026"; }

.icon-ikony-dziedzictwo-02:before {
  content: "\e027"; }

.icon-n1:before {
  font-family: "untitled-font-1" !important;
  content: "a"; }

.icon-n2:before {
  font-family: "untitled-font-1" !important;
  content: "b"; }

.icon-n3:before {
  font-family: "untitled-font-1" !important;
  content: "c"; }

.icon-n4:before {
  font-family: "untitled-font-1" !important;
  content: "d"; }

.icon-n5:before {
  font-family: "untitled-font-1" !important;
  content: "e"; }

.icon-strzalka-link {
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle;
  font-size: 28px;
  line-height: 1; }

.icon-slider-braz-strzalka {
  color: #efe6dd;
  font-size: 50px;
  display: block; }

@font-face {
  font-family: 'GTAmerica';
  src: url("fonts/GT-America/GT-America-Standard-Regular.eot");
  /* IE9 Compat Modes */
  src: url("fonts/GT-America/GT-America-Standard-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/GT-America/GT-America-Standard-Regular.woff2") format("woff2"), url("fonts/GT-America/GT-America-Standard-Regular.woff") format("woff"), url("fonts/GT-America/GT-America-Standard-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'GTSectra';
  src: url("fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.eot");
  /* IE9 Compat Modes */
  src: url("fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.woff2") format("woff2"), url("fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.woff") format("woff"), url("fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: inherit;
  font-family: "GTSectra", Arial, sans-serif;
  font-weight: 100;
  line-height: 1.25; }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 1em;
  margin-bottom: 1em; }

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

h1,
.h1 {
  font-size: 35px; }
  @media (min-width: 740px) {
    h1,
    .h1 {
      font-size: 45px; } }

h2,
.h2 {
  font-size: 25px; }
  @media (min-width: 740px) {
    h2,
    .h2 {
      font-size: 30px; } }

.h2-sm {
  font-size: 25px; }
  @media (min-width: 740px) {
    .h2-sm {
      font-size: 30px; } }

h3,
.h3 {
  font-size: 18px; }
  @media (min-width: 740px) {
    h3,
    .h3 {
      font-size: 23px; } }

h4,
.h4 {
  font-size: 20px; }

h5,
.h5 {
  font-size: 16px;
  font-weight: 600; }

h6,
.h6 {
  font-size: 12px; }

h4 .icon-arrow-light-right {
  font-size: 18px;
  position: relative;
  top: 2px; }

.text-lead {
  font-size: 16px; }
  @media (min-width: 740px) {
    .text-lead {
      font-size: 18px; } }

.text-label, .control-label, .radio label,
.checkbox label {
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.66667em; }
  .text-label.computed, .computed.control-label, .radio label.computed,
  .checkbox label.computed {
    line-height: 1.05; }

.font-light {
  font-weight: normal;
  font-family: 'LatoLatinWebLight'; }

.font-normal {
  font-weight: normal; }

.font-semibold {
  font-weight: normal;
  font-family: 'LatoLatinWebSemibold'; }

p {
  margin: 0 0 20px; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-normal-wrap {
  white-space: normal; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-introduction {
  font-size: 20px;
  margin-bottom: 56px; }

.clear-padding {
  padding: 0 !important; }

.clear-padding-right {
  padding-right: 0 !important; }

@media (max-width: 739px) {
  .text-uppercase-xs {
    text-transform: uppercase;
    font-size: 80%; }
  .clear-margin-bottom-xs {
    margin-bottom: 0; }
  .clear-padding-xs {
    padding: 0 !important; } }

.text-through {
  text-decoration: line-through; }

.text-base {
  color: #2d2d2d; }

.text-dark {
  color: #2d2d2d; }

.text-primary {
  color: #3762a8; }

.text-secondary {
  color: #ad003b; }

.lead {
  font-size: 20px; }

.text-bold {
  font-weight: bold; }

.text-big {
  font-size: 120%; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 25px; }
  ul li,
  ol li {
    padding-left: 15px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-small-padding {
  padding-left: 15px; }

.list-inline > li {
  display: inline-block; }

.highlight {
  background: #3762a8 !important;
  color: #FFF !important; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.clearfix {
  clear: both; }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
  .clearfix:after {
    clear: both; }

.center-block {
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.hidden {
  display: none; }

.show {
  display: block; }

.invisible {
  visibility: hidden; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }
  .img-responsive.center {
    margin-left: auto;
    margin-right: auto; }

.img-circle {
  border-radius: 50%; }

.lh-28 {
  line-height: 28px; }

.mt-42 {
  margin-top: 42px; }

@-ms-viewport {
  width: device-width; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 1009px) {
  .visible-sm-block {
    display: block !important; }
  .visible-sm-inline {
    display: inline !important; }
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (max-width: 739px) {
  .visible-xs-block {
    display: block !important; }
  .visible-xs-inline {
    display: inline !important; }
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (max-width: 739px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 741px) and (max-width: 1009px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 1011px) and (max-width: 1219px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1221px) {
  .hidden-lg {
    display: none !important; } }

.device-v-xs {
  display: none; }

.device-v-sm {
  display: none; }

.device-v-md {
  display: none; }

.device-v-lg {
  display: none; }

@media (max-width: 739px) {
  .device-v-xs {
    display: block !important; } }

@media (min-width: 740px) and (max-width: 1009px) {
  .device-v-sm {
    display: block !important; } }

@media (min-width: 1010px) and (max-width: 1219px) {
  .device-v-md {
    display: block !important; } }

@media (min-width: 1220px) {
  .device-v-lg {
    display: block !important; } }

/*
@media (max-width: $screen-xs-max) {
 // @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @media (max-width: $screen-xs-max) {
    display: block !important;
  }
}
.visible-xs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (max-width: $screen-xs-max) {
 // @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @media (max-width: $screen-xs-max) {
    display: block !important;
  }
}
.visible-xs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include responsive-visibility('.visible-sm');
}
.visible-sm-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: block !important;
  }
}
.visible-sm-inline {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline !important;
  }
}
.visible-sm-inline-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include responsive-visibility('.visible-md');
}
.visible-md-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: block !important;
  }
}
.visible-md-inline {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline !important;
  }
}
.visible-md-inline-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-lg-min) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @media (min-width: $screen-lg-min) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) {
    display: inline-block !important;
  }
}
@media (max-width: $screen-xs-max) {
  //@include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  //@include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  //@include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg-min) {
 // @include responsive-invisibility('.hidden-lg');
}
*/
.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

* {
  box-sizing: border-box; }

*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100% !important; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  margin: 0;
  font-family: "GTAmerica", sans-serif; }
  body > * {
    position: relative; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

textarea {
  border: 0;
  background: #f0f1f2;
  resize: vertical; }

a {
  display: inline-block;
  text-decoration: none;
  color: #efe6dd;
  -webkit-transition: all linear 0.2ss;
  -moz-transition: all linear 0.2ss;
  -ms-transition: all linear 0.2ss;
  -o-transition: all linear 0.2ss;
  transition: all linear 0.2ss;
  -webkit-transform: translateZ(0); }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none; }
  a:hover, a:focus {
    text-decoration: none; }
  a::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 1px;
    width: 0;
    transition: width 0.2s linear;
    will-change: width;
    content: '';
    background: #efe6dd; }
  a:hover::after {
    width: 100%; }
  a.secondary-underline {
    display: inline-block; }
    a.secondary-underline::after {
      background: #af5f46; }

@keyframes bounce-1 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

.clear-margin-all {
  margin: 0; }

hr {
  margin: 50px 0;
  border: 0;
  border-bottom: 1px solid #efe6dd; }
  hr.brown {
    border-color: #af5f46; }

.link-default {
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 22px; }

.font-18 {
  font-size: 18px; }

.font-22 {
  font-size: 22px; }

.container-fluid {
  padding: 0 100px; }
  .container-fluid.section-default, .container-fluid.section-centered {
    padding: 30px; }
    @media (min-width: 740px) {
      .container-fluid.section-default, .container-fluid.section-centered {
        padding: 60px; } }
    @media (min-width: 1010px) {
      .container-fluid.section-default, .container-fluid.section-centered {
        padding: 100px; } }
  .container-fluid .row-centered, .container-fluid.section-centered {
    text-align: center; }

.container-force-0-distance {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important; }

.container, .conainer-relative {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }
  @media (min-width: 740px) {
    .container, .conainer-relative {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1010px) {
    .container, .conainer-relative {
      padding-left: 15px;
      padding-right: 15px;
      width: 960px; } }
  @media (min-width: 1220px) {
    .container, .conainer-relative {
      width: 1180px; } }

.conainer-relative {
  position: relative;
  overflow: hidden; }

.row {
  margin-left: -15px;
  margin-right: -15px;
  clear: both; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row .row {
    margin-left: 0;
    margin-right: 0; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }
  @media (max-width: 1009px) {
    .col-xs-offset-0 {
      margin-left: 0; } }

.col-xs-offset-1 {
  margin-left: 8.33333%; }
  @media (max-width: 1009px) {
    .col-xs-offset-1 {
      margin-left: 0; } }

.col-xs-offset-2 {
  margin-left: 16.66667%; }
  @media (max-width: 1009px) {
    .col-xs-offset-2 {
      margin-left: 0; } }

.col-xs-offset-3 {
  margin-left: 25%; }
  @media (max-width: 1009px) {
    .col-xs-offset-3 {
      margin-left: 0; } }

.col-xs-offset-4 {
  margin-left: 33.33333%; }
  @media (max-width: 1009px) {
    .col-xs-offset-4 {
      margin-left: 0; } }

.col-xs-offset-5 {
  margin-left: 41.66667%; }
  @media (max-width: 1009px) {
    .col-xs-offset-5 {
      margin-left: 0; } }

.col-xs-offset-6 {
  margin-left: 50%; }
  @media (max-width: 1009px) {
    .col-xs-offset-6 {
      margin-left: 0; } }

.col-xs-offset-7 {
  margin-left: 58.33333%; }
  @media (max-width: 1009px) {
    .col-xs-offset-7 {
      margin-left: 0; } }

.col-xs-offset-8 {
  margin-left: 66.66667%; }
  @media (max-width: 1009px) {
    .col-xs-offset-8 {
      margin-left: 0; } }

.col-xs-offset-9 {
  margin-left: 75%; }
  @media (max-width: 1009px) {
    .col-xs-offset-9 {
      margin-left: 0; } }

.col-xs-offset-10 {
  margin-left: 83.33333%; }
  @media (max-width: 1009px) {
    .col-xs-offset-10 {
      margin-left: 0; } }

.col-xs-offset-11 {
  margin-left: 91.66667%; }
  @media (max-width: 1009px) {
    .col-xs-offset-11 {
      margin-left: 0; } }

.col-xs-offset-12 {
  margin-left: 100%; }
  @media (max-width: 1009px) {
    .col-xs-offset-12 {
      margin-left: 0; } }

@media (max-width: 1009px) {
  [class*="col-"] {
    width: 100%;
    display: block; } }

@media (min-width: 740px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-0 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-1 {
    margin-left: 8.33333%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-1 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-2 {
    margin-left: 16.66667%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-2 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-3 {
    margin-left: 25%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-3 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-4 {
    margin-left: 33.33333%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-4 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-5 {
    margin-left: 41.66667%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-5 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-6 {
    margin-left: 50%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-6 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-7 {
    margin-left: 58.33333%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-7 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-8 {
    margin-left: 66.66667%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-8 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-9 {
    margin-left: 75%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-9 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-10 {
    margin-left: 83.33333%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-10 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-11 {
    margin-left: 91.66667%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-11 {
      margin-left: 0; } }

@media (min-width: 740px) {
  .col-sm-offset-12 {
    margin-left: 100%; } }
  @media (min-width: 740px) and (max-width: 1009px) {
    .col-sm-offset-12 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-0 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-1 {
    margin-left: 8.33333%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-1 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-2 {
    margin-left: 16.66667%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-2 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-3 {
    margin-left: 25%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-3 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-4 {
    margin-left: 33.33333%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-4 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-5 {
    margin-left: 41.66667%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-5 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-6 {
    margin-left: 50%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-6 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-7 {
    margin-left: 58.33333%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-7 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-8 {
    margin-left: 66.66667%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-8 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-9 {
    margin-left: 75%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-9 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-10 {
    margin-left: 83.33333%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-10 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-11 {
    margin-left: 91.66667%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-11 {
      margin-left: 0; } }

@media (min-width: 1010px) {
  .col-md-offset-12 {
    margin-left: 100%; } }
  @media (min-width: 1010px) and (max-width: 1009px) {
    .col-md-offset-12 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-0 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-1 {
    margin-left: 8.33333%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-1 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-2 {
    margin-left: 16.66667%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-2 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-3 {
    margin-left: 25%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-3 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-4 {
    margin-left: 33.33333%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-4 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-5 {
    margin-left: 41.66667%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-5 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-6 {
    margin-left: 50%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-6 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-7 {
    margin-left: 58.33333%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-7 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-8 {
    margin-left: 66.66667%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-8 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-9 {
    margin-left: 75%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-9 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-10 {
    margin-left: 83.33333%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-10 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-11 {
    margin-left: 91.66667%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-11 {
      margin-left: 0; } }

@media (min-width: 1220px) {
  .col-lg-offset-12 {
    margin-left: 100%; } }
  @media (min-width: 1220px) and (max-width: 1009px) {
    .col-lg-offset-12 {
      margin-left: 0; } }

.page-header {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden; }
  .page-header .navbar-header {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto; }
  .page-header .header-video-wrapper {
    width: 100%;
    padding-top: 75%; }
    @media (max-width: 1009px) {
      .page-header .header-video-wrapper {
        padding-top: 40%; } }
    .page-header .header-video-wrapper .video-switcher {
      opacity: 0;
      transition: opacity .2s linear; }
      .page-header .header-video-wrapper .video-switcher.show {
        opacity: 1; }
  .page-header .video-switch::after {
    content: none; }
  .page-header .video-switch:hover {
    color: #4c9099; }
  .page-header .header-title {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 30px;
    text-align: center; }
    .page-header .header-title h1 {
      margin-top: 75px;
      margin-bottom: 20px;
      color: #efe6dd;
      font-family: "GTSectra", Arial, sans-serif;
      font-size: 200px; }
    .page-header .header-title p {
      margin: 0;
      color: #efe6dd;
      font-family: "GTSectra", Arial, sans-serif;
      font-size: 30px;
      line-height: 42px; }
  .page-header .header-title-left {
    text-align: left;
    width: 100%; }
  .page-header .header-title-normal-size h1 {
    font-size: 45px; }
    @media (min-width: 740px) {
      .page-header .header-title-normal-size h1 {
        font-size: 65px; } }
    @media (min-width: 1010px) {
      .page-header .header-title-normal-size h1 {
        font-size: 75px; } }
    @media (min-width: 1220px) {
      .page-header .header-title-normal-size h1 {
        font-size: 100px; } }
  .page-header .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    width: 80px;
    color: #fff;
    font-size: 80px;
    cursor: pointer;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-2;
    animation-timing-function: ease; }
    @media (max-width: 1009px) {
      .page-header .scroll-down {
        top: 70%; } }
    @media (min-width: 1010px) {
      .page-header .scroll-down {
        bottom: 50px; } }
    @media (max-height: 400px) {
      .page-header .scroll-down {
        font-size: 60px;
        bottom: 10px;
        top: unset; } }
    .page-header .scroll-down i::before {
      transform: rotate(180deg);
      position: relative;
      display: block; }
  .page-header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%); }

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 42, 39, 0.54); }

@keyframes bounce-2 {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0); } }

@media (max-width: 1009px) {
  .page-header .header-title {
    top: 40%;
    width: 90%; }
    .page-header .header-title .row {
      width: 80%;
      margin: 0 auto; }
    .page-header .header-title h1 {
      margin-top: 0;
      font-size: 42px; }
    .page-header .header-title p {
      font-size: 18px;
      line-height: 20px; }
  .page-header .header-title-left {
    text-align: center;
    padding: 0 5%; } }

@media (max-width: 739px) {
  .page-header .header-title h1 {
    font-size: 32px; } }

.lang-switch {
  left: 130px; }

.nav-tabs {
  padding: 0;
  margin: 0; }

.main-menu-wrapper {
  position: relative;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  z-index: 1; }
  @media (max-width: 1009px) {
    .main-menu-wrapper {
      display: none; } }
  .main-menu-wrapper nav {
    width: 100%; }
  .main-menu-wrapper a {
    position: relative;
    font-size: 16px;
    color: #efe6dd; }

.main-menu {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 0; }
  .main-menu > li:last-child {
    margin-right: 0; }
  .main-menu .main-menu-home {
    display: none; }
  .main-menu .active a::after {
    width: 100%; }

.slicknav_menu, .slicknav_menu * {
  background: transparent; }

.slicknav_menu .list-inline > li {
  display: block; }

.slicknav_menu a::after {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: none;
  background: transparent; }

@media (min-width: 1010px) {
  .slicknav_menu {
    display: none; } }

.slicknav_menu .slicknav_btn {
  margin: 10px; }
  @media (max-width: 1009px) {
    .slicknav_menu .slicknav_btn {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1056; } }
  .slicknav_menu .slicknav_btn a {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: none; }

.slicknav_menu .slicknav_icon {
  width: auto;
  height: auto; }

.slicknav_menu .slicknav_icon-bar {
  box-shadow: none;
  width: 30px;
  height: 1px; }
  .slicknav_menu .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 9px; }

.slicknav_menu .slicknav_nav {
  padding: 50px 10px 110px 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1055;
  transform: translateX(-50%) translateY(-50%);
  background-color: #4c9099;
  transition: background-color .5s linear;
  will-change: background-color; }
  .slicknav_menu .slicknav_nav.slicknav_hidden {
    background-color: transparent; }
    .slicknav_menu .slicknav_nav.slicknav_hidden a {
      opacity: 0; }
  .slicknav_menu .slicknav_nav a {
    display: inline-block;
    padding: 5px 0;
    margin: 15px 15px 0 15px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: none;
    transition: all .2s linear;
    will-change: all; }
  .slicknav_menu .slicknav_nav a:hover {
    border-radius: 0;
    background: inherit;
    color: inherit; }
  .slicknav_menu .slicknav_nav .active a {
    border-bottom: 1px solid; }
  .slicknav_menu .slicknav_nav .main-menu-home {
    display: block; }

.footer-main {
  font-size: 22px;
  background: #af5f46;
  color: #efe6dd;
  padding: 85px 0; }
  .footer-main .container, .footer-main .conainer-relative {
    padding: 80px 0 50px; }
  .footer-main a {
    color: #efe6dd;
    display: inline-block;
    margin-bottom: 20px; }
  .footer-main .row {
    display: flex;
    justify-content: flex-start; }

.footer-menu-wrapper {
  padding-bottom: 62px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efe6dd; }
  .footer-menu-wrapper > * {
    flex: 2; }
    .footer-menu-wrapper > *:first-child {
      flex: 1; }

.footer-address {
  position: relative;
  line-height: 32px;
  font-size: 18px; }

.footer-links {
  padding-bottom: 62px;
  margin-bottom: 40px;
  border-bottom: 1px solid #efe6dd; }
  .footer-links a {
    font-family: "GTSectra", Arial, sans-serif;
    font-size: 30px;
    flex: 2; }
    .footer-links a:first-child {
      flex: 1; }
  .footer-links .look-more {
    margin-top: 0; }

.back-to-top {
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 18px;
  text-align: center; }
  .back-to-top:hover::after {
    content: none; }
  .back-to-top i {
    display: block;
    margin: 20px 0;
    font-size: 80px; }

.footer-copyright {
  font-size: 15px;
  margin-right: 20px; }
  .footer-copyright span {
    padding: 0 10px;
    display: inline-block; }

@media (max-width: 1009px) {
  .footer-main {
    padding: 40px 0; }
    .footer-main a {
      margin-bottom: 20px; }
    .footer-main .row {
      display: block; }
  .footer-links {
    padding: 0;
    border: 0; }
    .footer-links a {
      margin: 0;
      padding: 40px 0;
      width: 100%;
      border-bottom: 1px solid #efe6dd; }
  .footer-menu-wrapper .footer-menu {
    display: flex;
    margin-bottom: 40px; }
    .footer-menu-wrapper .footer-menu div {
      float: left;
      width: 100%; }
  .back-to-top {
    position: relative;
    width: 100%;
    font-size: 18px;
    text-align: center; }
    .back-to-top i {
      font-size: 80px; } }

@media (max-width: 739px) {
  .footer-main {
    margin: 0;
    padding: 0; }
    .footer-main .container-fluid {
      padding: 0 20px; }
  .footer-menu {
    font-size: 18px; }
  .footer-address {
    line-height: 16px;
    font-size: 12px; }
  .footer-copyright {
    font-size: 10px;
    margin-left: -15px;
    margin-right: -15px; }
    .footer-copyright span {
      padding: 0 5px; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  text-align: center;
  padding: 10px 0;
  display: block;
  margin: 0; }

label {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 6px;
  position: relative; }

input:active, input:focus {
  outline: none; }

.control-label {
  font-size: 14px; }

input[type="radio"],
input[type="checkbox"] {
  line-height: normal; }

input[type="checkbox"] + label {
  margin-bottom: 0; }

input[type="file"] {
  display: block; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none; }

.form-control, .form-control-inline {
  display: block;
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  font-family: "GTAmerica", sans-serif;
  font-size: 18px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  appearance: none; }
  .form-control::-ms-expand, .form-control-inline::-ms-expand {
    border: 0;
    background-color: transparent; }
  .form-control:placeholder, .form-control-inline:placeholder {
    color: #ccc;
    opacity: 1; }
  .form-control[disabled], .form-control-inline[disabled], .form-control[readonly], .form-control-inline[readonly],
  fieldset[disabled] .form-control,
  fieldset[disabled] .form-control-inline {
    background-color: gray;
    opacity: 1; }
  .form-control[disabled], .form-control-inline[disabled],
  fieldset[disabled] .form-control,
  fieldset[disabled] .form-control-inline {
    cursor: not-allowed; }
  .form-control.white-version, .white-version.form-control-inline {
    background: #fff; }

.form-control-inline {
  display: inline-block;
  vertical-align: middle; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

textarea.form-control, textarea.form-control-inline {
  height: auto;
  resize: none; }

.form-control-dark {
  background: #232a3c;
  color: #d0c9d0; }
  .form-control-dark:focus {
    color: #d0c9d0;
    outline: none; }

.form-control-blue {
  color: #fff;
  font-size: 15px; }
  .form-control-blue:focus {
    outline: none; }

.form-group-search {
  padding: 0; }
  .form-group-search .input-group-btn button {
    border-width: 0px;
    color: #fff;
    margin-left: 0px; }
    .form-group-search .input-group-btn button:active, .form-group-search .input-group-btn button:target {
      border-width: 0;
      color: #fff;
      outline: none; }
    .form-group-search .input-group-btn button.btn-default, .form-group-search .input-group-btn button.btn-primary {
      padding-right: 10px; }
    .form-group-search .input-group-btn button .icon-search {
      position: relative;
      top: 3px;
      -webkit-transition: all linear 0.2ss;
      -moz-transition: all linear 0.2ss;
      -ms-transition: all linear 0.2ss;
      -o-transition: all linear 0.2ss;
      transition: all linear 0.2ss;
      -webkit-transform: translateZ(0); }

@media (max-width: 739px) {
  .form-group-search .form-control, .form-group-search .form-control-inline {
    font-size: 14px; } }

.form-group {
  margin-bottom: 10px;
  position: relative; }

@media (max-width: 739px) {
  .form-group.vertical-align-middle, .form-group.vertical-align-middle-right, .form-group.vertical-align-middle-center {
    display: block; } }

.form-group-action {
  margin-top: 30px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin: 15px 0; }
  .radio label,
  .checkbox label {
    min-height: 16px;
    padding: 0 0 0 27px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    position: relative; }
    .radio label.label-normal,
    .checkbox label.label-normal {
      font-size: 14px;
      text-transform: none; }
    .radio label.text-sm,
    .checkbox label.text-sm {
      font-size: 12px; }

.checkbox label {
  padding: 0; }

.radio input[type="radio"] {
  opacity: 0; }
  .radio input[type="radio"] + i {
    display: block; }
    .radio input[type="radio"] + i:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 16px;
      height: 16px;
      border: 1px solid #909a9b;
      display: block;
      z-index: 2;
      border-radius: 50%; }
  .radio input[type="radio"]:checked + i:after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 10px;
    height: 10px;
    text-align: center;
    display: block;
    z-index: 3;
    border-radius: 50%; }

.checkbox input[type="checkbox"] + i {
  display: block; }
  .checkbox input[type="checkbox"] + i:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 20px;
    height: 20px;
    background: #fff;
    display: block;
    z-index: 2; }

.checkbox input[type="checkbox"]:checked + i:after {
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  content: '\2713';
  background: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  display: block;
  z-index: 3; }

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  position: absolute;
  margin-left: -27px; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-right: 20px; }

@media (max-width: 1009px) {
  .radio-inline,
  .checkbox-inline {
    display: block; } }

@media (max-width: 739px) {
  .radio-inline {
    margin-bottom: 10px; } }

.has-feedback {
  position: relative; }
  .has-feedback .form-control, .has-feedback .form-control-inline {
    padding-right: 50px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  pointer-events: none; }

.has-feedback label ~ .form-control-feedback {
  top: 6em; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.form-control-box {
  position: relative; }

.form-group.has-action {
  position: relative; }
  .form-group.has-action .form-control, .form-group.has-action .form-control-inline {
    padding-right: 40px; }
  .form-group.has-action button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-image: none;
    background: none;
    border: none;
    padding: 0;
    text-align: center;
    font-size: 1.5em;
    cursor: pointer; }
    .form-group.has-action button:focus {
      outline: none; }

.control-help {
  position: relative; }
  .control-help span {
    display: block;
    line-height: 15px; }

.form-horizontal {
  margin: 30px 0; }
  .form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
    clear: both; }
    .form-horizontal .form-group:before, .form-horizontal .form-group:after {
      content: " ";
      display: table; }
    .form-horizontal .form-group:after {
      clear: both; }
  .form-horizontal .control-label {
    line-height: 40px;
    margin-bottom: 0;
    text-align: right; }
    .form-horizontal .control-label.big-line-height {
      line-height: 50px; }

@media (max-width: 1219px) {
  .form-horizontal .form-group {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center; }
  .form-horizontal .control-label {
    line-height: normal; }
    .form-horizontal .control-label.big-line-height {
      line-height: normal; } }

@media (max-width: 739px) {
  .form-horizontal {
    margin: 15px 0; }
    .form-horizontal small {
      margin-bottom: 15px; }
    .form-horizontal hr {
      margin-bottom: 15px;
      margin-top: -5px; }
    .form-horizontal .form-group {
      display: block;
      margin-bottom: 15px; }
      .form-horizontal .form-group.vertical-align-middle-xs {
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -ms-flex-align: center;
        -ms-flex: 1 1 auto; }
      .form-horizontal .form-group p {
        margin: 0px;
        margin-bottom: 10px; }
      .form-horizontal .form-group .radio {
        margin: 0;
        margin-bottom: 10px; }
      .form-horizontal .form-group .radio-inline:last-child {
        margin-bottom: 0; }
      .form-horizontal .form-group .checkbox {
        margin: 0; }
    .form-horizontal .control-label {
      font-size: 14px;
      text-align: left;
      margin-bottom: 5px; }
    .form-horizontal .form-control, .form-horizontal .form-control-inline {
      margin-bottom: 5px; }
    .form-horizontal .checkbox:last-child {
      margin-bottom: 0; } }

.delivery-date .row {
  margin-bottom: 30px; }
  .delivery-date .row:first-child {
    margin-top: 30px; }

@media (max-width: 739px) {
  .delivery-date .row {
    margin-bottom: 15px; }
    .delivery-date .row:first-child {
      margin-top: 0; }
    .delivery-date .row:last-child {
      margin-bottom: 0; }
  .delivery-date .vertical-align-middle, .delivery-date .vertical-align-middle-right, .delivery-date .vertical-align-middle-center {
    display: block; }
    .delivery-date .vertical-align-middle > div, .delivery-date .vertical-align-middle-right > div, .delivery-date .vertical-align-middle-center > div {
      text-align: left; }
  .delivery-date .form-horizontal .vertical-align-middle, .delivery-date .form-horizontal .vertical-align-middle-right, .delivery-date .form-horizontal .vertical-align-middle-center {
    text-align: center; }
    .delivery-date .form-horizontal .vertical-align-middle .col-sm-2, .delivery-date .form-horizontal .vertical-align-middle-right .col-sm-2, .delivery-date .form-horizontal .vertical-align-middle-center .col-sm-2 {
      text-align: center;
      margin-bottom: 5px; }
    .delivery-date .form-horizontal .vertical-align-middle .col-md-8, .delivery-date .form-horizontal .vertical-align-middle-right .col-md-8, .delivery-date .form-horizontal .vertical-align-middle-center .col-md-8 {
      display: inline-block; } }

.payment-method form .row {
  margin-top: -40px; }

@media (max-width: 739px) {
  .payment-method .radio {
    margin-bottom: 0; }
    .payment-method .radio label {
      line-height: 14px; } }

@media (max-width: 739px) {
  .common-error > * {
    display: inline-block; }
  .common-error span.or {
    margin: 10px 10px !important; } }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }

.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0); }

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.modal-header, .modal-header-secondary {
  font-family: "GTSectra", Arial, sans-serif;
  font-weight: 400;
  padding: 15px;
  padding-bottom: 0;
  position: relative; }
  .modal-header .icon-close, .modal-header-secondary .icon-close {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 23px; }

.modal-header .close, .modal-header-secondary .close {
  margin-top: -2px; }

.modal-header-secondary {
  background: #f5f5f5; }

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-weight: 400;
  font-size: 25px; }

@media (max-width: 739px) {
  .modal-title {
    padding-right: 20px; } }

.modal-body {
  position: relative;
  padding: 15px; }
  .modal-body .tab-default-content hr {
    margin: 0; }
  .modal-body .tab-default-content .tab-pane {
    padding-top: 18px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0; }

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px; }

.modal-footer .btn-block + .btn-block {
  margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header-secondary:before,
.modal-header:after,
.modal-header-secondary:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table; }

.clearfix:after,
.modal-header:after,
.modal-header-secondary:after,
.modal-footer:after {
  clear: both; }

.file-modal .modal-header, .file-modal .modal-header-secondary {
  margin-bottom: 22px; }

.file-modal .modal-body p {
  margin-bottom: 27px; }

.file-modal .modal-body button.btn-primary {
  margin-bottom: 30px; }

[class^=section-color-] {
  color: #efe6dd; }

section {
  position: relative; }

.section-color-1 {
  background: #b5997c; }

.section-color-2 {
  background: #4c9099; }

.section-color-3 {
  color: #af5f46;
  background: #efe6dd; }
  .section-color-3 a {
    color: #af5f46; }
    .section-color-3 a::after {
      background: #af5f46; }
  .section-color-3 .section-pagination {
    color: #af5f46; }

.section-parallax {
  position: relative;
  height: 80%;
  overflow: hidden; }

.section-flex,
.list-flex {
  display: flex; }

.section-flex > div {
  padding: 0 20px 0 0;
  flex: 1 1 33%; }

.list-flex.list-flex-margin-bottom {
  margin-bottom: 70px; }

.list-flex > li {
  flex: 1 1 0;
  padding: 0 40px;
  font-family: "GTAmerica", sans-serif;
  font-size: 16px; }
  .list-flex > li:first-child {
    padding-left: 0; }
  .list-flex > li:last-child {
    padding-right: 0; }
  .list-flex > li .subheader {
    margin-bottom: 10px;
    font-weight: 600; }
  .list-flex > li h4 {
    margin-bottom: 30px 0 25px;
    font-size: 30px; }
    .list-flex > li h4 i {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      font-size: 40px; }
  .list-flex > li a {
    display: block;
    margin: 20px 0 50px; }

.section-title {
  margin-bottom: 60px;
  font-size: 72px;
  line-height: 86px; }

.section-title-0-margin {
  margin-top: 0; }

.section-desc {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 36px; }
  .section-desc p {
    padding-right: 2em; }
  .section-desc > h2 {
    margin-top: 0; }

.section-list {
  padding-top: 20px; }

.section-pagination {
  margin-bottom: 15px;
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 30px;
  font-style: italic;
  color: #efe6dd; }

.parallax-window {
  height: 80vh;
  background: transparent; }

.section-tile {
  display: block; }
  .section-tile.tile-full .tile-link {
    padding: 30px; }

.section-tile-v {
  display: flex;
  align-items: flex-start; }

.section-tile-gallery .tile-images > * {
  border: 10px solid #efe6dd; }

.model-wrapper img {
  height: 750px;
  max-height: 100vh; }

.section-map {
  position: relative;
  z-index: 1; }

.section-icons {
  justify-content: space-between;
  font-family: "GTSectra", Arial, sans-serif; }
  .section-icons > div {
    text-align: center;
    font-size: 22px; }
  .section-icons i {
    display: block;
    font-size: 135px; }

.section-picture {
  height: 80vh;
  position: relative;
  overflow: hidden; }
  .section-picture img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.section-footer {
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 42px; }

.section-logo {
  margin-top: 50px; }
  .section-logo i {
    font-size: 50px; }
  .section-logo .logo {
    margin-right: 80px; }

.section-img img {
  max-height: 650px;
  max-width: 100%; }

.section-sponsors .sponsors-logo {
  margin: 0; }

.section-contact i {
  font-size: 130px; }

.section-partners {
  margin-bottom: 60px; }
  .section-partners a {
    font-family: "GTSectra", Arial, sans-serif; }
  .section-partners img {
    max-height: 150px;
    height: 20vw;
    margin-top: 10px; }
  .section-partners .partners-tel-info {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 36px;
    font-family: "GTAmerica", sans-serif; }

.section-thematic-break {
  margin: 90px 0 60px; }

.section-4-1 .section-icons {
  margin-top: 90px;
  margin-bottom: 90px; }

.section-4-1 .row {
  text-align: center; }

@media (max-width: 1009px) {
  .section-icons > div {
    margin-bottom: 60px; }
  .section-icons i {
    font-size: 80px;
    line-height: 60px; }
  .section-img img {
    height: 90vw; }
  .section-flex,
  .list-flex {
    display: block; }
  .list-flex {
    margin-bottom: 0px; }
    .list-flex.list-flex-margin-bottom {
      margin-bottom: 0px; }
    .list-flex > li {
      padding: 0;
      margin-bottom: 30px; }
  .section-footer,
  .section-title {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 1.31; }
  .section-desc {
    font-size: 16px;
    line-height: 1.88; }
    .section-desc p {
      padding-right: 0; }
    .section-desc.section-desc-left-column {
      margin-bottom: 0; }
  .section-pagination {
    font-size: 18px; }
  .section-map {
    margin: 0; }
    .section-map .map-outline img {
      width: 100%; }
  .section-partners img {
    max-width: 150px;
    width: 40vw;
    max-height: initial;
    height: auto; }
  .section-partners #fwl-logo {
    max-width: 350px;
    width: 80vw; }
  .section-tile-v {
    display: block; }
    .section-tile-v .tile-movie, .section-tile-v .tile-text {
      width: 100%; }
  .section-4-1 .section-icons {
    margin-top: 30px;
    margin-bottom: 30px; } }

@media (max-width: 739px) {
  .section-tile-v {
    display: block; }
  .section-with-video .container-fluid.section-default {
    margin-bottom: 0;
    padding-bottom: 0; }
  .section-thematic-break {
    margin: 50px 0; } }

@media (max-width: 424px) {
  .tile-models > * {
    flex: 1 1 90%;
    max-width: 90%; }
  .section-with-img.section-desc {
    margin-bottom: 0;
    padding-top: 0; }
  .section-with-img img {
    margin: 5px 0 !important;
    width: 100% !important;
    float: none !important; } }

.vertical-align-middle, .vertical-align-middle-right, .vertical-align-middle-center {
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  -ms-flex: 1 1 auto; }

.vertical-align-top {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
  -ms-flex-align: center; }

.vertical-align-bottom {
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  align-items: flex-end;
  -ms-flex-align: center; }

.vertical-align-middle-right {
  justify-content: flex-end; }

.vertical-align-middle-center {
  justify-content: center; }

@media (max-width: 739px) {
  .vertical-align-middle-xs {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex: 1 1 auto; } }

.vertical-line-base {
  display: inline-block;
  height: 13px;
  margin: 0 11px;
  position: relative; }
  .vertical-line-base:before {
    content: "";
    display: block;
    height: 13px;
    position: absolute;
    top: 2px;
    width: 2px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */ }

.pswp__container {
  transition: .3s ease-in-out all; }

::-ms-clear {
  display: none; }

.overflow-h {
  overflow: hidden; }

.btn-default, .btn-primary {
  background: #000;
  border-radius: 0;
  border: 0px;
  color: #fff;
  font-family: "GTAmerica", sans-serif;
  font-size: 16px;
  padding: 9px 30px;
  text-transform: uppercase;
  -webkit-transition: all ease 0.2ss;
  -moz-transition: all ease 0.2ss;
  -ms-transition: all ease 0.2ss;
  -o-transition: all ease 0.2ss;
  transition: all ease 0.2ss;
  -webkit-transform: translateZ(0); }
  .btn-default:hover, .btn-primary:hover, .btn-default:active, .btn-primary:active, .btn-default:focus, .btn-primary:focus {
    border: 0px;
    border-radius: 0;
    color: #ccc;
    outline: none; }
  .btn-default:hover, .btn-primary:hover {
    color: #fff; }
  .btn-default.red-btn, .red-btn.btn-primary {
    color: #fff; }

.btn-primary {
  background: #fff;
  color: #000; }
  .btn-primary:hover {
    background: #fff; }

form form-center {
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  width: 50%; }

form .label {
  font-size: 20px; }

form .form-checkbox {
  font-size: 16px;
  margin-bottom: 10px; }
  form .form-checkbox:last-child {
    margin-right: 0; }

form .form-radio {
  margin-right: 30px; }

form .form-group {
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  -ms-flex: 1 1 auto;
  margin-bottom: 20px; }
  form .form-group label {
    margin-bottom: 0; }

form .form-button-wrapper {
  margin-top: 25px; }

@media (max-width: 1009px) {
  form.form-center {
    width: 65%; } }

@media (max-width: 739px) {
  form .label {
    font-size: 14px; }
  form.form-center {
    width: 80%; }
    form.form-center label {
      font-size: 14px;
      margin-bottom: 5px; }
    form.form-center .vertical-align-middle, form.form-center .vertical-align-middle-right, form.form-center .vertical-align-middle-center {
      display: block; }
    form.form-center .form-radio {
      margin-bottom: 10px; }
      form.form-center .form-radio:last-child {
        margin-bottom: 0; }
      form.form-center .form-radio .vertical-align-middle, form.form-center .form-radio .vertical-align-middle-right, form.form-center .form-radio .vertical-align-middle-center {
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -ms-flex-align: center;
        -ms-flex: 1 1 auto; }
    form.form-center .form-checkbox .vertical-align-middle, form.form-center .form-checkbox .vertical-align-middle-right, form.form-center .form-checkbox .vertical-align-middle-center {
      display: -webkit-flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -ms-flex-align: center;
      -ms-flex: 1 1 auto; } }

@media (max-width: 424px) {
  form.form-center {
    width: 95%; } }

label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px; }

input,
textarea {
  background: #fff;
  border: 1px solid #b0cbdc;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0;
  max-width: 570px;
  outline: none;
  padding: 15px 40px 15px 10px;
  width: 100%; }

input {
  padding: 10px 40px 10px 10px; }
  input.small-input {
    max-width: 140px; }
  input.datepicker {
    max-width: 130px; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group .form-control, .input-group .form-control-inline {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control-inline:focus {
      outline: none;
      z-index: 3; }
  .input-group .btn-input,
  .input-group input {
    border: 0; }
  .input-group input {
    font-size: 16px; }

.input-gray {
  background-color: #ccc;
  border: 0;
  color: gray;
  padding: 5px; }
  .input-gray:focus {
    outline: none; }

.input-group-addon,
.input-group-btn, .input-group .form-control, .input-group .form-control-inline {
  display: table-cell; }

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative;
    margin-left: 1px; }
    .input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
      z-index: 2; }
  .input-group-btn .btn-clear {
    border: none;
    background: none;
    font-size: 20px;
    padding: 0 15px; }
  .input-group-btn .icon-search:before {
    left: 0;
    position: relative;
    top: 3px; }

.input-xs {
  width: 60px; }

.input-sm {
  width: 75px; }

input[type="checkbox"],
input[type="radio"] {
  display: none; }

input[type="checkbox"] + label,
input[type="radio"] + label {
  min-height: 30px;
  margin-bottom: 0;
  padding-left: 0;
  font-weight: 400; }

input[type="checkbox"] + label + .error {
  padding-left: 52px; }

input[type="radio"] + label {
  padding-left: 0; }

input[type="checkbox"] + label em,
input[type="radio"] + label em {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  left: 0;
  margin-right: 10px;
  max-width: 30px;
  min-width: 30px;
  position: relative;
  top: 0px;
  vertical-align: middle;
  width: 100%; }
  input[type="checkbox"] + label em:before,
  input[type="radio"] + label em:before {
    background-color: transparent;
    border-radius: 0;
    bottom: 0;
    content: "";
    height: 25px;
    left: 2px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0px;
    width: 15px;
    z-index: -1; }

input[type="radio"] + label em:before {
  left: 0; }

input[type="checkbox"] + label span,
input[type="radio"] + label span {
  padding-left: 10px;
  width: 100%; }

@media (max-width: 1009px) {
  input[name="agreement-check"]:checked + label span {
    width: 90%; } }

input[type="radio"] + label span {
  font-family: "GTAmerica", sans-serif;
  font-size: 16px;
  font-weight: 400; }

@media (max-width: 739px) {
  input[type="radio"] + label span {
    font-size: 16px; } }

.checkbox input[type="checkbox"] + label em:before {
  top: -3px; }

input[type="radio"] + label em {
  border-radius: 50%; }

input[type="checkbox"]:checked + label em,
input[type="radio"]:checked + label em {
  display: table;
  margin-right: 10px;
  position: relative;
  top: 0px; }
  input[type="checkbox"]:checked + label em:before,
  input[type="radio"]:checked + label em:before {
    background-color: #909a9b;
    z-index: 1; }

input[type="radio"]:checked + label em {
  margin-right: 10px; }

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  margin-left: 0px; }

input[type="checkbox"] + label em:before,
input[type="radio"] + label em:before {
  font-family: "";
  content: "\e051";
  font-size: 16px;
  opacity: 0; }

input[type="radio"] + label em:before {
  background: #0040b2;
  border-radius: 50%;
  content: "";
  font-size: 20px;
  height: 14px;
  opacity: 1;
  width: 14px; }

input[type="radio"]:checked + label em:before {
  background: #0040b2; }

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  padding-left: 0; }

input[type="checkbox"]:checked + label em {
  color: #ad003b; }
  input[type="checkbox"]:checked + label em:before {
    background-color: transparent;
    height: 18px;
    width: 22px;
    opacity: 1; }

input[type="checkbox"].error + label em {
  border: 1px solid #b42547; }

input[type="checkbox"] + label em:before,
input[type="checkbox"]:checked + label em:before,
input[type="radio"] + label em:before,
input[type="radio"]:checked + label em:before {
  -webkit-transition: opacity ease 0.2ss;
  -moz-transition: opacity ease 0.2ss;
  -ms-transition: opacity ease 0.2ss;
  -o-transition: opacity ease 0.2ss;
  transition: opacity ease 0.2ss;
  -webkit-transform: translateZ(0); }

label.error {
  display: none; }

label.error {
  display: block;
  color: #b42547;
  margin-top: 5px; }

textarea.error,
input.error {
  border: 1px solid #b42547; }

.main-logo {
  position: relative;
  display: block;
  z-index: 10;
  text-align: center; }
  @media (max-width: 1009px) {
    .main-logo {
      position: absolute;
      top: 0;
      left: 0;
      padding: 15px 0 0 20px; } }
  .main-logo::after {
    content: none;
    all: unset; }
  .main-logo:hover::after {
    content: 'home';
    font-size: 15px;
    font-style: italic;
    font-family: GTSectra,Arial,sans-serif;
    position: absolute;
    bottom: -5px;
    left: 0; }
  .main-logo i {
    font-size: 100px;
    color: #efe6dd; }
  @media (max-width: 739px) {
    .main-logo i {
      font-size: 80px; } }

.link-mapa {
  padding: 20px;
  position: absolute;
  color: #efe6dd;
  font-size: 14px;
  text-align: center;
  background: #a06249; }
  @media (max-width: 1009px) {
    .link-mapa {
      bottom: 0;
      left: 0;
      z-index: 1050; } }
  @media (min-width: 1010px) {
    .link-mapa {
      top: 0;
      right: 0; } }
  .link-mapa:hover {
    background: #4c9099; }
    .link-mapa:hover::after {
      content: none; }
  .link-mapa i {
    display: block;
    font-size: 30px; }

.is-here {
  position: absolute;
  left: 16%;
  top: 165px;
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 23px; }

.coordinates {
  position: absolute;
  left: 27%;
  font-family: "GTAmerica", sans-serif;
  font-size: 18px; }
  .coordinates > span {
    display: block;
    font-size: 18px;
    color: #efe6dd; }

.picture-container .picture-wrapper {
  margin: 0 auto;
  position: relative;
  width: 50%;
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 22px; }
  .picture-container .picture-wrapper a {
    display: inline-block; }
  .picture-container .picture-wrapper img {
    max-width: 100%;
    margin-bottom: 30px; }

.tile-movie {
  position: relative;
  height: 100%;
  width: 50%;
  overflow: hidden; }
  .tile-movie::before {
    content: '';
    float: left;
    padding-top: 100%; }
  .tile-movie video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%); }

.tile-text {
  color: #efe6dd;
  font-family: "GTAmerica", sans-serif;
  font-size: 18px;
  overflow: auto; }
  .tile-text > div {
    position: absolute;
    left: 10%;
    width: 80%; }
  .tile-text.color-1 {
    background-color: #4c9399; }
  .tile-text ul {
    padding: 20px 40px; }
    .tile-text ul li {
      font-family: "GTAmerica", sans-serif; }

.tile-models {
  display: flex;
  flex: 1 1 25%;
  flex-wrap: wrap;
  justify-content: space-between; }
  .tile-models > * {
    flex: 1 1 25%;
    max-width: 25%; }
    .tile-models > *::before {
      content: '';
      float: left;
      padding-top: 100%; }

.tile-images {
  display: flex;
  flex: 1 1 25%;
  flex-wrap: wrap;
  justify-content: space-between; }
  .tile-images > * {
    flex: 1 1 25%; }
    .tile-images > *::before {
      content: '';
      float: left;
      padding-top: 100%; }

.section-tile-v .tile-images {
  flex: 1 1 50%; }
  .section-tile-v .tile-images > * {
    flex: 1 1 50%; }

.tile-image {
  position: relative; }
  .image-1.tile-image {
    background: url("../assets/images/tile/tile1.jpg") center;
    background-size: cover; }
  .image-2.tile-image {
    background: url("../assets/images/tile/tile2.jpg") center;
    background-size: cover; }
  .image-detal-1.tile-image {
    background: url("../assets/images/detale/tile3.jpg") center;
    background-size: cover; }
  .image-detal-2.tile-image {
    background: url("../assets/images/detale/tile1.jpg") center;
    background-size: cover; }
  .image-detal-3.tile-image {
    background: url("../assets/images/detale/tile2.jpg") center;
    background-size: cover; }
  .image-miejsce-1.tile-image {
    background: url("../assets/images/miejsce/kwadrat1.jpg") center;
    background-size: cover; }
  .image-miejsce-2.tile-image {
    background: url("../assets/images/miejsce/kwadrat2.jpg") center;
    background-size: cover; }
  .image-miejsce-3.tile-image {
    background: url("../assets/images/miejsce/kwadrat3.jpg") center;
    background-size: cover; }
  .image-dziedziczenie-1.tile-image {
    background: url("../assets/images/dziedzictwo/6.png") center;
    background-size: cover; }
  .image-dziedziczenie-2.tile-image {
    background: url("../assets/images/dziedzictwo/2.png") center;
    background-size: cover; }
  .image-dziedziczenie-3.tile-image {
    background: url("../assets/images/dziedzictwo/5.png") center;
    background-size: cover; }
  .image-dziedziczenie-4.tile-image {
    background: url("../assets/images/dziedzictwo/3.png") center;
    background-size: cover; }
  .image-dziedziczenie-5.tile-image {
    background: url("../assets/images/dziedzictwo/4.png") center;
    background-size: cover; }
  .image-kontakt-1.tile-image {
    background: url("../assets/images/kontakt/1.png") center;
    background-size: cover; }
  .image-kontakt-2.tile-image {
    background: url("../assets/images/kontakt/2.png") center;
    background-size: cover; }

.tile-link {
  padding: 30px 43px;
  font-size: 22px; }
  .tile-link::after {
    content: none; }
  .tile-link:hover .icon-strzalka-link {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-1;
    animation-timing-function: ease; }
  .tile-link:hover::after {
    all: unset;
    content: '';
    font-family: GTSectra,Arial,sans-serif;
    position: absolute;
    bottom: 40px;
    right: 47px; }
  .tile-link[class*="color-"] {
    color: #efe6dd; }
  .tile-link.color-1 {
    background: #af5f46; }
    .tile-link.color-1:hover {
      background: #4c9099; }
  .tile-link.color-2 {
    background: #b58c61; }
    .tile-link.color-2:hover {
      background: #af5f46; }
  .tile-link.color-3 {
    background: #efe6dd; }
  .tile-link.color-4 {
    background: #4c9399; }
  .tile-link i {
    display: block;
    font-size: 50px;
    margin-top: 10px;
    margin-left: 0; }

.tile-extra-info {
  font-size: 22px;
  position: absolute;
  bottom: 30px;
  right: 30px; }

.unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none; }

.video-container img {
  height: 100%; }

.video-container .video-js {
  width: 100%; }

.video-container .look-more {
  font-size: 22px;
  margin-bottom: 60px; }

.video-player {
  position: relative;
  margin: 0 auto;
  padding: 0;
  line-height: 0;
  max-width: 960px; }
  .video-player .play {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #efe6dd;
    cursor: pointer;
    z-index: 1; }
  .video-player.video-wrapper {
    overflow: visible; }
  .video-player.video-play .play {
    display: none; }

.map-outline {
  position: relative;
  top: 70px;
  z-index: 1; }

.list-default, .list-secondary {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 22px; }
  .list-default li, .list-secondary li {
    padding-left: 30px;
    margin-bottom: 1em;
    position: relative; }
    .list-default li:before, .list-secondary li:before {
      font-family: kosciol-webfont;
      font-size: 9px;
      content: '\e020';
      display: inline-block;
      height: 10px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 9px; }
  .list-default.list-no-marker li, .list-no-marker.list-secondary li {
    padding-left: 0; }
    .list-default.list-no-marker li:before, .list-no-marker.list-secondary li:before {
      display: none; }

.list-secondary {
  font-family: "GTAmerica", sans-serif;
  font-size: 15px;
  line-height: 30px; }
  .list-secondary li::before {
    color: #af5f46;
    top: 0;
    font-family: kosciol-webfont;
    content: "\e020";
    font-size: 9px; }

.h3-normal {
  font-family: "GTAmerica", sans-serif;
  font-size: 26px;
  margin-bottom: 60px; }

.day-night-wrapper {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center; }
  .day-night-wrapper .line {
    margin: 15px 0;
    display: block;
    height: 1px;
    width: 40px;
    transform: rotate(-20deg);
    background: #efe6dd; }
  .day-night-wrapper a.active {
    color: #b58c61; }
  .day-night-wrapper i {
    display: block;
    font-size: 15px;
    padding: 10px 0; }

.video-wrapper {
  position: relative;
  height: 80vh;
  overflow: hidden; }
  .video-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%); }
  .video-wrapper .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 42, 39, 0.54); }

.open-model {
  display: block;
  cursor: pointer;
  position: relative; }
  .open-model:hover .icon-strzalka-link {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-1;
    animation-timing-function: ease; }
  .open-model .link-icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #efe6dd; }
    .open-model .link-icon-wrapper i {
      font-size: 134px; }
    .open-model .link-icon-wrapper .icon-strzalka-link {
      font-size: 50px;
      margin: 0;
      margin-top: 20px; }
    .open-model .link-icon-wrapper div {
      font-size: 18px; }

.look-more {
  display: inline-flex;
  align-items: center; }

.emphesize-text,
.look-more {
  font-family: "GTSectra", Arial, sans-serif;
  margin-top: 30px;
  font-size: 22px; }

.model-hover {
  position: relative; }
  .model-hover:hover .hover-desc {
    opacity: 1; }
  .model-hover:hover img {
    opacity: .3; }
  .model-hover .hover-desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    opacity: 0;
    transition: opacity .2s linear; }
    .model-hover .hover-desc i {
      font-size: 100px;
      display: block; }

.read-more {
  display: inline-flex;
  align-items: center;
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 22px;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: none; }
  .read-more.center {
    justify-content: center; }
  .read-more i {
    font-size: 28px;
    height: 28px;
    position: relative;
    margin-left: 12px; }
  .read-more:hover::after {
    width: 0; }

.read-more-wrapper {
  opacity: 1;
  transition: opacity 1s ease-in-out; }
  .read-more-wrapper p {
    margin: 0 0 20px; }
  .read-more-wrapper:not(.show) {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    margin: 0; }
  .read-more-wrapper .read-more-button,
  .read-more-wrapper .read-less-button {
    justify-content: center; }
  .read-more-wrapper + .read-more .read-more-button {
    display: inline-flex;
    align-items: center; }
  .read-more-wrapper + .read-more .read-less-button {
    display: none; }
  .read-more-wrapper.show + .read-more .read-more-button {
    display: none; }
  .read-more-wrapper.show + .read-more .read-less-button {
    display: inline-flex;
    align-items: center; }

.sponsors-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px; }
  .sponsors-logo img {
    height: 15vh;
    max-height: 170px;
    min-height: 100px;
    padding-right: 30px; }

.open-dialog {
  cursor: pointer; }
  .open-dialog a {
    position: relative; }
    .open-dialog a img {
      transition: opacity .2s linear; }
    .open-dialog a:hover img {
      opacity: .3; }
    .open-dialog a:hover .hover-desc {
      opacity: 1; }
    .open-dialog a .hover-desc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 15px;
      opacity: 0;
      transition: opacity .2s linear; }
      .open-dialog a .hover-desc i {
        font-size: 100px;
        display: block; }

.section-img-absolute {
  width: 50%;
  position: absolute; }
  .section-img-absolute img {
    position: relative; }

.section-img-absolute-bottom-right {
  bottom: 0;
  right: 0; }

.section-img-absolute-bottom-left {
  bottom: 0;
  left: 0; }

.section-img-absolut-centered {
  text-align: center; }

.images-130 {
  margin-top: 90px; }
  .images-130 img {
    max-width: 130px; }

.k-coordinates {
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 45px; }

.k-title {
  margin-bottom: 20px;
  text-decoration: underline; }

#map {
  height: 80vh; }

#map-image {
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-image: url("../assets/images/kontakt/mapka.png"); }

.image-gallery-wrapper {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between; }
  .image-gallery-wrapper a {
    display: inline-block;
    align-self: flex-end; }

#images-model-jezus-na-teczy {
  width: 75%;
  bottom: -5px; }
  @media (max-width: 1009px) {
    #images-model-jezus-na-teczy {
      width: 50%; } }
  @media (max-width: 739px) {
    #images-model-jezus-na-teczy {
      width: 75%; } }

#images-miejsce-model {
  height: 70vh;
  bottom: -8vh; }

.link-default:hover .icon-strzalka-link {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-1;
  animation-timing-function: ease; }

.logo-mkidn {
  background-image: url("../assets/images/logotypy/ministerstwo-kultury.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 120px; }
  .logo-mkidn:hover {
    background-image: url("../assets/images/logotypy/ministerstwo-kultury_kolor.png"); }

.link-icon:hover {
  opacity: .3; }

.row-video-wrapper {
  margin-top: 30px; }

@media (max-width: 1009px) {
  .sponsors-logo img {
    padding-right: 15px; }
  .picture-container .picture-wrapper {
    width: 100%; }
    .picture-container .picture-wrapper img {
      margin-left: -25%; }
  .day-night-wrapper {
    top: auto;
    bottom: 25px;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: none; }
    .day-night-wrapper .line {
      display: inline-block;
      transform: rotate(-70deg); }
  .section-img-absolute-bottom-left,
  .section-img-absolute-bottom-right {
    position: relative;
    text-align: center;
    width: 100%; }
  .tile-models > * {
    flex: 1 1 30%;
    max-width: 30%; } }

@media (max-width: 739px) {
  .row-video-wrapper {
    overflow: hidden;
    width: 100vw;
    margin: 30px -30px 0 -30px;
    padding: 0; }
    .row-video-wrapper .video-container {
      padding: 0; }
  .sponsors-logo img {
    min-height: 150px; }
  .read-more i {
    font-size: 22px; }
  .tile-models > * {
    flex: 1 1 50%;
    max-width: 50%; }
  .tile-images {
    display: block; }
    .tile-images > *::before {
      padding-top: 0; }
  .tile-link {
    display: block; }
  .tile-image {
    display: block;
    width: 100vw;
    height: 100vw; }
  .tile-text {
    width: 100vw;
    height: 100%;
    padding: 30px;
    overflow: auto; }
    .tile-text > div {
      transform: translate(0, 0);
      position: static; }
  .tile-extra-info {
    position: initial; }
  span.tile-link,
  .image-detal-1, .image-miejsce-1, .image-miejsce-3,
  .image-dziedziczenie-2, .image-dziedziczenie-3,
  .image-1, .image-2 {
    display: none; }
  #images-miejsce-model {
    height: 60vh; }
  .image-gallery-wrapper {
    display: block; }
    .image-gallery-wrapper a {
      padding: 30px; } }

@media (max-width: 424px) {
  .sponsors-logo img {
    min-height: 100px; }
  .tile-models > * {
    flex: 1 1 90%;
    max-width: 90%; } }

@media (pointer: coarse) {
  .model-hover .hover-desc {
    font-size: 18px;
    animation: "bounce-opacity-2" 5s 0s infinite;
    -moz-animation: "bounce-opacity-2" 5s 0s infinite;
    -webkit-animation: "bounce-opacity-2" 5s 0s infinite;
    -o-animation: "bounce-opacity-2" 5s 0s infinite; }
    .model-hover .hover-desc i {
      margin: 15px;
      line-height: 0;
      padding: 0;
      height: 94px; }
  .model-hover img {
    animation: "bounce-opacity-1" 5s 0s infinite;
    -moz-animation: "bounce-opacity-1" 5s 0s infinite;
    -webkit-animation: "bounce-opacity-1" 5s 0s infinite;
    -o-animation: "bounce-opacity-1" 5s 0s infinite; } }

/* Animation Keyframes*/
@keyframes bounce-opacity-1 {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@-moz-keyframes bounce-opacity-1 {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@-webkit-keyframes bounce-opacity-1 {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@-o-keyframes bounce-opacity-1 {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes bounce-opacity-2 {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes bounce-opacity-2 {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes bounce-opacity-2 {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes bounce-opacity-2 {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.image-modele.tile-image {
  background-size: cover;
  background-position: center;
  cursor: pointer; }

.image-modele-1.tile-image {
  background: url("../assets/images/modele/1.png") center;
  background-size: cover; }

.image-modele-2.tile-image {
  background: url("../assets/images/modele/2.png") center;
  background-size: cover; }

.image-modele-3.tile-image {
  background: url("../assets/images/modele/3.png") center;
  background-size: cover; }

.image-modele-4.tile-image {
  background: url("../assets/images/modele/4.png") center;
  background-size: cover; }

.image-modele-5.tile-image {
  background: url("../assets/images/modele/5.png") center;
  background-size: cover; }

.image-modele-6.tile-image {
  background: url("../assets/images/modele/6.png") center;
  background-size: cover; }

.image-modele-7.tile-image {
  background: url("../assets/images/modele/7.png") center;
  background-size: cover; }

.image-modele-big.tile-image {
  background: url("../assets/images/modele/model.png") center;
  background-size: cover; }

.tile-gallery {
  position: relative; }
  .tile-gallery:hover .tile-gallery-bckg {
    opacity: 1; }
  .tile-gallery:hover > span {
    opacity: 1; }
  .tile-gallery .tile-gallery-bckg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(76, 147, 153, 0.63);
    opacity: 0;
    content: '';
    transition: opacity .2s linear;
    will-change: opacity;
    z-index: 0; }
  .tile-gallery > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    opacity: 0;
    transition: opacity .2s linear;
    will-change: opacity;
    z-index: 10; }
    .tile-gallery > span > * {
      display: block;
      color: #fff; }
    .tile-gallery > span i {
      position: relative;
      display: block;
      margin-bottom: 10px;
      font-size: 77px; }
    .tile-gallery > span span {
      font-size: 15px; }

.model-category-list {
  display: block;
  list-style: none;
  margin: auto;
  margin-bottom: 60px;
  padding: 0;
  font-size: 22px; }
  .model-category-list > li {
    margin-right: 5%;
    margin-bottom: 1em;
    position: relative;
    display: inline-block; }
    .model-category-list > li:last-child {
      margin-right: 0; }
    .model-category-list > li a.active::after {
      width: 100%; }

.sketchfab-embed-wrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

#dialog, #dialog-polityka {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: .2em;
  outline: 0;
  z-index: 1140;
  background: #efe6dd;
  color: #af5f46; }
  #dialog.show-model, #dialog-polityka.show-model {
    display: block; }
  #dialog .container-fluid.section-default, #dialog-polityka .container-fluid.section-default {
    padding: 0px; }
  #dialog .close-dialog, #dialog-polityka .close-dialog {
    font-size: 60px;
    line-height: 0;
    border-radius: 100%;
    display: block;
    position: fixed;
    top: 10px;
    right: 30px;
    z-index: 1155;
    cursor: pointer; }
    #dialog .close-dialog .close, #dialog-polityka .close-dialog .close {
      color: #af5f46; }

@media (max-width: 1009px) {
  .model-category-list {
    margin-bottom: 30px; }
    .model-category-list > li {
      margin-right: 30px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0 0 50px 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow {
  display: none !important; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  font-family: "GTAmerica", sans-serif;
  font-size: 18px; }
  .slick-dots > li {
    position: relative; }
    .slick-dots > li::before, .slick-dots > li::after {
      content: ' ';
      display: inline-block;
      height: 1px;
      width: 0;
      background-color: #fff;
      border-bottom: 0;
      margin-bottom: 0;
      transform: scaleX(1);
      transition: width .5s ease-in-out;
      position: relative;
      top: -6px; }
    .slick-dots > li.slick-active::after {
      width: 20vw; }
    .slick-dots > li a {
      padding: 0 10px; }

.section-color-3 .slick-dots > li::before, .section-color-3 .slick-dots > li::after {
  background-color: #af5f46; }

.slider-image-wrapper img {
  width: 100%; }

@media (max-width: 1009px) {
  .slick-dots {
    position: relative;
    margin-bottom: 40px; }
  .slick-list {
    padding-bottom: 20px; } }

@media (max-width: 424px) {
  .slick-dots {
    width: 100%;
    margin-left: 30px; }
    .slick-dots > li.slick-active::after {
      width: 10vw; }
  .slick-slider {
    transform: translateX(-30px); }
    .slick-slider .slider-image-wrapper,
    .slick-slider .slick-list {
      width: 100vw; }
  .carousel-next-link-centered {
    display: none; } }

.ui-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vw;
  padding: .2em;
  outline: 0;
  z-index: 1140;
  background: #efe6dd;
  color: #af5f46; }
  .ui-dialog * {
    z-index: 1150; }
  .ui-dialog .ui-dialog-titlebar {
    position: relative;
    padding: .4em 1em;
    margin: 0 auto;
    width: 1180px;
    text-align: right; }
  .ui-dialog .ui-dialog-title {
    float: left;
    margin: .1em 0;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ui-dialog .ui-dialog-titlebar-close {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 40px;
    color: #af5f46;
    background: none;
    border: 0;
    cursor: pointer; }
    .ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus {
      outline: none; }
  .ui-dialog .ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em 1em;
    background: none;
    overflow: auto;
    width: 100% !important; }
  .ui-dialog .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin-top: .5em;
    padding: .3em 1em .5em .4em; }
  .ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: right; }
  .ui-dialog .ui-dialog-buttonpane button {
    margin: .5em .4em .5em 0;
    cursor: pointer; }
  .ui-dialog .ui-resizable-handle {
    display: none; }
  .ui-dialog .container-fluid.section-default {
    padding: 30px; }

.icon-close-model:hover, .icon-close-model:focus {
  outline: none; }

.ui-button-icon-space,
.ui-widget-overlay {
  display: none; }

.dialog-header {
  font-family: "GTSectra", Arial, sans-serif; }
  .dialog-header h2 {
    font-size: 54px; }
  .dialog-header h3 {
    font-size: 30px; }

.dialog-wrapper img {
  width: 100%; }

.dialog-list {
  padding: 30px 0 0;
  margin: 0;
  list-style: none; }
  .dialog-list > li {
    padding-bottom: 15px;
    margin-bottom: 15px;
    line-height: 38px;
    border-bottom: 1px solid #af5f46; }

.dialog-item-title {
  font-family: "GTSectra", Arial, sans-serif;
  font-size: 22px; }

.dialog-item-desc {
  font-family: "GTAmerica", sans-serif;
  font-size: 18px; }

.dialog-desc p, .dialog-desc li {
  font-size: 18px;
  line-height: 36px; }

.dialog-img-wrapper {
  height: 75vh;
  padding: 30px 0; }

.col-2 {
  padding-left: 50px; }

.dialog-carousel-wrapper {
  display: flex;
  align-items: center; }

.dialog-carousel {
  width: 80%; }
  .dialog-carousel .slider-img-wrapper {
    width: 181px;
    height: 181px; }
    .dialog-carousel .slider-img-wrapper img {
      width: 100%; }

.dialog-pagination {
  width: 20%;
  text-align: center;
  cursor: pointer; }
  .dialog-pagination i {
    font-size: 60px; }

#model-embed-wrapper {
  border-top: 1px solid #af5f46;
  border-bottom: 1px solid #af5f46; }

@media (max-width: 739px) {
  .ui-widget-content .container-fluid {
    padding: 0; }
  .ui-dialog .ui-dialog-titlebar {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1155;
    widows: 100%; }
  .dialog-img-wrapper {
    height: auto; }
  .dialog-header h2 {
    font-size: 32px; }
  #model-embed {
    display: block;
    margin: 0;
    width: 95vw;
    max-width: 90vh;
    height: 95vh;
    max-height: 90vw; } }

@media (max-width: 424px) {
  .ui-dialog .container-fluid.section-default {
    padding: 0; } }

.carousel-wrapper {
  position: relative;
  width: calc(100% - 50px); }
  .carousel-wrapper .carousel {
    position: inherit; }
  .carousel-wrapper .carousel-next-link:hover::after,
  .carousel-wrapper .slick-dots a:hover::after {
    content: none; }
  .carousel-wrapper .carousel-next-link i {
    font-size: 100px; }
  .carousel-wrapper .carousel-next-link-centered {
    position: absolute;
    margin: 0 0 50px 0;
    bottom: 0;
    right: 15%;
    height: 50%;
    text-align: center; }
  .carousel-wrapper.carousel-with-desc .carousel-next-link-centered {
    right: -25%; }

@media (max-width: 1219px) {
  .carousel-wrapper .section-desc {
    font-size: 16px;
    line-height: 1.88; } }

@media (max-width: 1009px) {
  .carousel-wrapper .carousel-next-link {
    visibility: hidden; } }

.timeline-row {
  overflow: hidden; }

.timeline-wrapper {
  margin: -70px 0 0 0; }

.timeline {
  box-sizing: border-box;
  position: relative; }
  .timeline *,
  .timeline *::before,
  .timeline *::after {
    box-sizing: inherit; }
  .timeline:not(.timeline--horizontal)::before {
    background-color: #ddd;
    bottom: 0;
    content: '';
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px;
    z-index: 1; }

.timeline__wrap {
  overflow: hidden;
  position: relative;
  max-height: 450px;
  height: 80vh;
  z-index: 2; }

.timeline__item {
  font-size: 1rem;
  position: relative;
  width: 50%;
  z-index: 2; }
  .timeline__item::after {
    background-color: #4c9399;
    border: 2px solid #efe6dd;
    border-radius: 50%;
    content: '';
    height: 20px;
    position: absolute;
    right: -10px;
    transform: translatey(-50%);
    top: 50%;
    width: 20px;
    z-index: 1; }
  .timeline__item.brown::after {
    background-color: #b5997c; }
  .timeline__item.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 0; }
  .timeline__item.fadein {
    animation-name: fadein; }

.timeline__item--left {
  left: 0; }

.timeline__item--right {
  left: 50%;
  padding: 10px 0 10px 40px; }
  .timeline__item--right::after {
    left: -10px; }
  .timeline__item--right .timeline__content::before {
    border-bottom: 10px solid transparent;
    border-right: 12px solid #ccc;
    border-left: none;
    border-top: 10px solid transparent;
    left: -12px; }
  .timeline__item--right .timeline__content::after {
    border-bottom: 9px solid transparent;
    border-right: 11px solid #fff;
    border-left: none;
    border-top: 9px solid transparent;
    left: -10px; }

.timeline__content {
  border-radius: 10px;
  color: #efe6dd;
  display: block;
  position: relative; }
  .timeline__content h2 {
    font-family: "GTSectra", Arial, sans-serif;
    font-size: 27px;
    margin: 30px 0 10px; }
  .timeline__content p {
    font-family: "GTAmerica", sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin-top: 60px; }

.timeline--horizontal {
  font-size: 0;
  padding: 0 0 115px 0;
  white-space: nowrap; }
  .timeline--horizontal .timeline-divider {
    background-color: #ddd;
    display: block;
    height: 1px;
    left: 10px;
    position: absolute;
    transform: translatey(-50%);
    right: 0;
    z-index: 1; }
  .timeline--horizontal .timeline__items {
    transition: all 0.8s;
    will-change: transform; }
  .timeline--horizontal .timeline__item {
    display: inline-block;
    left: 0;
    position: relative;
    transition: none;
    white-space: normal;
    top: 50%;
    transform: translate(0, -50%);
    padding-right: 60px;
    height: 200px !important; }
    .timeline--horizontal .timeline__item::after {
      right: auto; }
    .timeline--horizontal .timeline__item .timeline__item__inner {
      display: table;
      height: 100%;
      width: 100%; }
    .timeline--horizontal .timeline__item .timeline__content__wrap {
      display: table-cell;
      margin: 0;
      padding: 0;
      vertical-align: middle; }
    .timeline--horizontal .timeline__item .timeline__content::before {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #ccc;
      left: 50%;
      right: auto;
      transform: translatex(-50%);
      top: 100%; }
    .timeline--horizontal .timeline__item .timeline__content::after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      left: 50%;
      right: auto;
      transform: translatex(-50%);
      top: 100%; }
    .timeline--horizontal .timeline__item--bottom {
      transform: translate(0, -50%) !important; }

.timeline-nav-button {
  outline: none;
  position: absolute;
  bottom: 0px;
  top: auto !important;
  height: 100px;
  width: 100px;
  background: transparent;
  border: none;
  z-index: 100; }
  .timeline-nav-button:disabled {
    opacity: .5;
    pointer-events: none; }
  .timeline-nav-button::before {
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    transform: translatey(-50%);
    top: 50%;
    left: 0;
    color: #efe6dd; }
  .timeline-nav-button--prev {
    left: 0; }
    .timeline-nav-button--prev::before {
      font-family: "kosciol-webfont";
      font-size: 100px;
      content: '\e009';
      transform: translateY(-50%) rotate(180deg); }
  .timeline-nav-button--next {
    right: 0; }
    .timeline-nav-button--next::before {
      font-family: "kosciol-webfont";
      font-size: 100px;
      content: '\e009'; }

.timeline--mobile {
  padding: 0; }
  .timeline--mobile::before {
    left: 10px !important;
    margin: 0 !important; }
  .timeline--mobile .timeline__item {
    left: 0;
    padding-left: 40px;
    padding-right: 0;
    width: 100%; }
    .timeline--mobile .timeline__item::after {
      left: 2px;
      margin: 0; }
    .timeline--mobile .timeline__item .timeline__content::before {
      left: -12px;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      border-left: none;
      border-top: 12px solid transparent; }
    .timeline--mobile .timeline__item .timeline__content::after {
      left: -10px;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
      border-left: none;
      border-top: 10px solid transparent; }

@keyframes fadein {
  0% {
    opacity: 0;
    top: 70px; }
  100% {
    opacity: 1;
    top: 0px; } }

@keyframes liftup {
  0% {
    top: 0px; }
  100% {
    top: -15px; } }

@media (max-width: 739px) {
  .timeline-nav-button {
    height: 80px;
    width: 80px; }
    .timeline-nav-button--prev::before {
      font-size: 80px; }
    .timeline-nav-button--next::before {
      font-size: 80px; } }
